// https://github.com/elastic/eui/blob/master/wiki/consuming.md#fonts
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import '@elastic/eui/src/themes/amsterdam/theme_dark';
@import '~rc-tabs/assets/index.css';

@mixin beforeHighlightedDatapoint {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  background-color: #cf0030;
  vertical-align: middle;
}


body {
  height: 100%;
  //overflow-y: hidden;
  @include euiHeaderAffordForFixed;

  #root {
    height: 100%;
  }

  .euiOverlayMask {
    z-index: 6000;
  }

  .euiFlyout {
    z-index: 6000;
  }

  .euiToolTip {
    z-index: 99999;
  }
}


.schema-section-header {
  padding: .75em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 1.1em;
    font-weight: 600;
    display: inline-block;
    padding-left: .25em;
    vertical-align: middle;
  }
}

.schema-section-footer {
  padding: .75em;
}

.euiFormControlLayout {
  .no-search-icon {
    padding-left: 12px;
    + div.euiFormControlLayoutIcons {
      display: none;
    }
  }
}

.move-cursor:hover {
  cursor: move;
}

.selected-items-facet {
  .euiFacetButton__content {
    flex-direction: row-reverse;

    > * + * {
      margin-inline-end: 8px;
    }
  }
}

.annotation-value-field-wrap, .annotation-table-value-field-wrap, .datapoint-control {
  .euiFormControlLayout {
    height: unset;
  }

  &.highlighted:before {
    @include beforeHighlightedDatapoint;
  }
}

.euiFieldText {
  &.annotation-value-field {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: bold;
    text-align: end;
    height: unset;

    &:focus {
      background-image: none;
      background-color: transparent;
      box-shadow: none;
    }
  }

   &.annotation-table-value-field {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    text-align: start;
    height: unset;

    &:focus {
      background-image: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.euiSelect {
  &.annotation-value-enum {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    padding: 0 12px O O;
    margin: 0;
    font-weight: bold;
    text-align: end;
    height: unset;

    &:focus {
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      max-width: 200px;
    }
  }
}

// Make collapsible group title the same height as bottom bar so bottom bar top side
// is aligned with top side of the most bottom collapsed group in sidebar.
.euiCollapsibleNavGroup .euiAccordion__triggerWrapper {
  padding: 8px 16px !important;
}

// Allow adjusting EuiSuperSelect and EuiComboBox width to parent width.
.euiSuperSelectControl {
  white-space: normal !important;
}
.euiComboBoxPill {
  .euiComboBoxPill--plainText {
      // TODO: Not working, the orignal attribute has already !important.
      white-space: normal !important;
  }
}


.not-active-table-field {
  min-height: 15px;
  width: 100%;
  cursor: crosshair;

  &.highlighted:before {
    @include beforeHighlightedDatapoint;
  }
}

.annotation-field-row.euiLink.euiLink--primary:focus {
    text-decoration: none;
}

// Show full text in badge with annotation messages, instead of ellipsis for long text.
.euiBadge {
  .euiBadge__text {
    .messages {
      white-space: normal !important;
    }
  }
}

.shadowDocumentPage, .react-pdf__Page__canvas {
  @include euiBottomShadowFlat;
}

// Without this the text layer in rendered PDF is misaligned:
// https://github.com/wojtekmaj/react-pdf/issues/556#issuecomment-621707116
.react-pdf__Page__textContent {
    position: absolute !important;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
}

.datapoint-control {
  width: 100%;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;

  &.active {
    border: 1px solid darkred;
  }
}

.annotation-datapoint-value {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.annotation-enum-value {
  &.euiText {
    font-weight: bold;
  }
}

.annotation-table-datapoint-value {
  padding-left: 2px;
  padding-right: 2px;
  width: 100%;
  display: flex;
  text-align: left !important;
  justify-content: left;
  align-items: center;
}

.annotation-table-enum-value {
  font-weight: normal;
  text-align: center;

  span {
    font-size: 12px;
  }
}

.annotation-text-value {
  text-align: right;

  &.euiText {
    font-weight: bold;
  }
}

.annotation-datapoint-add-button {
  text-align: right;
}

.table-cell-add-button {
  width: 100%;
  text-align: left;
}

.hovered-annotation-cell {
  color: #0d7ecf !important;
}

.schema-builder-flyout {
  .euiFlyoutBody__overflowContent {
    height: 100%;
  }
}

.sheets-list {
  display: flex;
  flex-wrap: wrap;

  button.sheet-tab {
    margin-right: 4px;
    margin-bottom: 4px;
  }
}

// Use EUI style for scrollbars in Ace editor
.ace_scrollbar-v {
    @include euiYScroll;
}

.ace_scrollbar-h {
    @include euiXScroll;
}

// Keep text color for subfolder items
.euiSideNavItem--branch {
    color: inherit !important;
}

// Do not show underline for selected folder in sidebar
.euiSideNavItemButton.euiSideNavItemButton-isSelected  .euiSideNavItemButton__label {
  text-decoration: none !important;
}

// To hide lines in EuiTable
// .euiTableFooterCell, .euiTableHeaderCell, .euiTableRowCell, .euiTableRowCellCheckbox, .euiTableHeaderCellCheckbox {
//   border-top: 0px !important;
//   border-bottom: 0px !important;
// }

// Tabs with document views
.rc-tabs-tab {
  background: none !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.rc-tabs {
  border: none !important;
}

.rc-tabs-tab-active {
  color: $euiLinkColor!important;
  display: none;
}

.rc-tabs-ink-bar {
  display: none;
  background: $euiLinkColor!important;
}

.rc-tabs-nav-wrap-ping-right::after {
  border-right: 1px solid $euiColorDarkShade !important;
  margin: 4px 0px 6px 0px
}

.rc-tabs-nav-wrap-ping-left::before {
  border-left: 1px solid $euiColorDarkShade !important;
  margin: 4px 0px 6px 0px
}

.rc-tabs-nav-more {
  border: none !important;
  background: none !important;
}

.rc-tabs-dropdown-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $euiPageBackgroundColor;
}
.rc-tabs-dropdown-menu-item {
  padding: 4px 8px;
  cursor: pointer;
  color: $euiTextColor!important;

  &:hover{
    color: #36a2ef!important;
  }
}

.rc-tabs-dropdown-menu-item-selected {
  background: red;
}

.table-annotation .euiTableRowCell {
  vertical-align: top;
}

// React Pivot
.reactPivot {
  background: transparent !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

.reactPivot-results tr:hover td {
  background: transparent !important;
  box-shadow: none;
}

.reactPivot-solo, .reactPivot-csvExport, .reactPivot-hideColumn,
th:hover .reactPivot-hideColumn, td:hover .reactPivot-solo {
  display: none !important
}

.euiDataGridRowCell--selected-row {
  background-color: rgba(54, 162, 239, 0.04) !important;
}

.euiDataGridRow--rowClassesDemoSelected {
  color: $euiColorDisabledText;
  background-color: rgba(54, 162, 239, 0.2) !important;

  .euiDataGridRowCell--selected-row {
    color: $euiColorPrimary;
  }
}

.tableResizableContainer {

  .euiResizablePanel__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
}

.hidden-outline .euiButtonGroup--medium label.euiButtonGroupButton:focus-within {
  &:focus-within {
    outline-style: unset !important;
  }
}

.referenceDataTitle {

  .euiFormControlLayout {
    max-width: 460px;
  }

  .euiFormControlLayout--group > .euiFormControlLayout__append {
    max-width: 60%;
  }
}

.euiModalBody.import-modal .euiModalBody__overflow {
  overflow: hidden;
}

.email_flyout .euiFlyoutBody__overflowContent {
  height: 100%;
}

.breadcrumb-link {
  color: $euiTextColor;

  &:hover{
    color: $euiColorPrimary;
    text-decoration: underline;
  }
}


.move-to-folder-popover {
  .euiPopover__anchor {
    height: 100%;
    width: 100%;
  }
}

